
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.inner {
    padding: 70px 0;
    color: #fff;
    @apply mx-auto px-5 lg:max-w-7xl flex flex-col  justify-between items-center text-center;
    h2 {
        @apply text-2xl mb-4 font-bold;
        @include md {
            @apply text-3xl;
        }
    }
    p {
        font-weight: 400;
        font-size: 15px;
        line-height: 32px;
        @include md {
            font-size: 18px;
        }
    }
}
.wrapper{
    background-image: url("/assets/images/bg-overlay.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}